<template>
  <section class="about">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper auth p-0 theme-two">
          <div class="row d-flex align-items-stretch">
            <div class="col-md-4 banner-section d-none d-md-flex align-items-stretch justify-content-center">
              <div class="slide-content bg-2"> </div>
            </div>
            <div class="col-12 col-md-8 h-100 bg-white">
              <div class="auto-form-wrapper d-flex align-items-center justify-content-center flex-column">
                <navbar-pages></navbar-pages>

                <h1 class="w-100 mb-4 text-left text-center-responsive">Acerca de SUPPORT</h1>
                <p>
                    La creación de un Observatorio Regional de la Energía en Andalucía se plantea como una acción piloto en 
                    el marco del proyecto SUPPORT, que fue financiado por el programa INTERREG Europe, fruto del intercambio 
                    de experiencias entre socios y con el objetivo de contribuir a que las Estrategias de Baja Emisión de Carbono 
                    y los planes energéticos de las autoridades locales sean más eficientes. De esta manera, contribuirá en gran 
                    medida a la consecución de los objetivos de la Estrategia Energética de Andalucía 2020.
                </p>
                <p>
                    El observatorio, a través de su plataforma online, promoverá el intercambio de información entre las autoridades 
                    locales andaluzas a través de la recopilación, gestión y sistematización de datos energéticos. Permitirá así la 
                    recogida y evaluación de datos sobre edificios públicos andaluces en una plataforma única, y su utilización en la 
                    elaboración de directrices de referencia para la actuación sobre edificios según su uso y otras características. 
                    Así, tendrá el efecto de aumentar las capacidades de los/las técnicos/as municipales que asuman las funciones de 
                    gestión de la energía.
                </p>

                <footer-pages></footer-pages>

              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
import NavbarPages from '@/components/NavbarPages'
import FooterPages from '@/components/FooterPages'

export default {
  name: "About",

  components: {
      NavbarPages,
      FooterPages
  }
};
</script>

<style scoped>

</style>
